const prod_baseurl = "https://api.cannyhr.com/";

const production = {
  domain: "cannyhr.com",
  domainName: "CannyTrack",
  routingUrl: "/CannyTrack",
  production: 1,
  graphql_endpoint: {
    signUp: prod_baseurl + "appmanager/registrationgraphql",
    planDetails: prod_baseurl + "planDetails/planDetails",
  },
  firebase_credentials: {
    apiKey: "AIzaSyAz3TaOCY3KlDFyrNZ_feZe9dENgG1rU7g",
    authDomain: "cannyhr-identity.firebaseapp.com",
    databaseURL: "https://cannyhr-identity.firebaseio.com",
    projectId: "cannyhr-identity",
    storageBucket: "cannyhr-identity.appspot.com",
    messagingSenderId: "769444509795",
  },
  ipAddressApi: "https://ipinfo.io?token=5fb71e3c6fae15",
};

let configuration = production;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...configuration,
};
